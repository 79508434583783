//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Smart extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Target } = this.props
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 ps-5 pe-5 m-0 b9s940" },
            //Element
            React.createElement( "label", { className: "float-start w-auto p-0 m-0 gc3a48" },
                //Element
                Title
            ),
            //Element
            React.createElement( "label", { className: "float-start w-auto p-0 m-0 mt-3 nbc058" },
                //Element
                Summary
            ),
            //Element
            React.createElement( "a", { className: "float-start w-auto p-0 m-0 mt-3 i7tk72", href: Target },
                //Element
                "Hemen Bakın"
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Smart.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Smart;
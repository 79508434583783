//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Popular extends Component {
    //Element
    componentDidMount(){
        //Element
        let JN577A = document.getElementById( "Web_Featured_1" )
        //Element
        JN577A.volume = 0
    }
    //Element
    render() {
        //Element
        const { ID, Title, Media, Target } = this.props
        //Element
        let Source = "https://erka.s3.eu-north-1.amazonaws.com/video/"
        //Element
        let Video  = Source + Media
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 v488wk" },
            //Element
            React.createElement( "video", { id: "Web_Featured_1", className: "float-start w-100 h-auto", src: Video, playsInline: true, loop: true, autoPlay: true, controls: false, muted: false } )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Popular.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Media  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Popular;
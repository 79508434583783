//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import propTypes from "prop-types";

//Element
class Copyright extends Component {
    //Element
    render() {
        //Element
        const { ID, Title } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 uw27j9" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 t405qk" },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "p", { className: "float-start w-100 p-0 pt-1 pb-1 m-0 mt-4 mb-3 u2p43p" },
                            //Element
                            Title
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Copyright.propTypes = {
    ID    : propTypes.string.isRequired,
    Title : propTypes.string.isRequired
}
//Element
export default Copyright;
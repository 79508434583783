//Install
import React, { Component } from "react";

//Element
class Pagination extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-end p-0 m-0 c7ar92" },
            //Element
            React.createElement( "button", { className: "d-flex w-100 justify-content-center align-items-center rounded-circle position-relative t003r7", type: "button" } ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-end p-0 m-0 y02vy3" } ),
            //Element
            React.createElement( "button", { className: "d-flex w-100 justify-content-center align-items-center rounded-circle position-relative w181kh", type: "button" } ),
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Pagination;
//Install
import React, { Component } from "react";

//Element
class Figure extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-absolute start-0 top-0 q819fu" },
            //Element
            React.createElement( "i", { className: "float-start w-auto p-0 m-0 position-absolute q285z4" } ),
            //Element
            React.createElement( "i", { className: "float-start w-auto p-0 m-0 position-absolute c3x07a" } ),
            //Element
            React.createElement( "i", { className: "float-start w-auto p-0 m-0 position-absolute c3n35z" } )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Figure;
//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Blog extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Date, Media, Target } = this.props
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-5 mb-5 v6568a", title: Title, href: Target },
            //Element
            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 q8p2f2" },

            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 g4co75" },
                //Element
                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center lh-1 r4v633" },
                    //Element
                    Date
                ),
                //Element
                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center jm47o9" },
                    //Element
                    Title
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Blog.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Date   : propTypes.string.isRequired,
    Media  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Blog;
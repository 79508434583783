//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as Icon } from "../../../Media/Icon/012.svg";

//Element
class Success extends Component {
    //Element
    Close = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Showcase = document.getElementById( "Web_Favorite_Success" )
        //Element
        Showcase.classList.toggle( "d-none" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { id: "Web_Product_Success", className: "float-start w-100 p-0 m-0 position-fixed start-0 top-0 d-none a10892" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-absolute ma2337" },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative ip434h" },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 f92332" },
                                    //Element
                                    React.createElement( "label", { className: "float-start w-auto p-0 m-0 d159rv" },
                                        //Element
                                        "URL Kopyalandı"
                                    )
                                ),
                                //Element
                                React.createElement( "button", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-absolute rounded-circle jb385k", type: "button", onClick: this.Close },
                                    //Element
                                    React.createElement( Icon, {} )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Success;
//Install
import React, { Component } from "react";

//Element
class Support extends Component {
    //Element
    componentDidMount(){
        //Element
        let Button = document.getElementsByClassName( "ok739d" )[0]
        //Element
        window.addEventListener( "scroll", () => {
            //Element
            if( document.documentElement.scrollTop > 700 ){
                //Element
                Button.classList.remove( "d-none" )
            //Element
            } else {
                //Element
                Button.classList.add( "d-none" )
            }
        })
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "button", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-fixed d-none ok739d", type: "button" },
            //Element
            "Beraber Çözelim"
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Support;
//Install
import React, { Component } from "react";
//Install
import Add from "./Add";
//Install
import Share from "./Share";
//Install
import Favorite from "./Favorite";

//Element
class Default extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 h-100 justify-content-center align-items-center p-0 m-0 position-absolute start-0 top-0 d2a4d7" },
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle t474t4" },
                //Element
                React.createElement( Favorite, {} )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 ms-2 rounded-circle t474t4" },
                //Element
                React.createElement( Add, {} )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 ms-2 rounded-circle t474t4" },
                //Element
                React.createElement( Share, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;
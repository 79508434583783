//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Blog from "./Content/Blog";
//Install
import Tower from "./Content/Product/Tower";
//Install
import Setup from "./Content/Services/Setup";
//Install
import Smart from "./Content/Services/Smart";
//Install
import Energy from "./Content/Services/Energy";
//Install
import Figure from "./Content/Figure";
//Install
import Service from "./Content/Service";
//Install
import Popular from "./Content/Popular";
//Install
import Product from "./Content/Product/Default";
//Install
import Campaign from "./Content/Campaign";
//Install
import Headline from "./Content/Headline";
//Install
import Featured from "./Content/Featured";
//Install
import Guarantee from "./Content/Services/Guarantee";
//Install
import Background from "./Content/Background";
//Install
import Paginate from "./Content/Pagination";

//Element
class Main extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "LG Klima ve Hava Temizleme Ürünleri - Erkaklima.com.tr'de"
        //Element
        Description.content = "LG Klima, Inverter Klima, 9000 BTU Klima, 12000 BTU Klima, 18000 BTU Klima, Salon Tipi Klima, VRF Klima ve Klima Fiyatları Erkaklima.com.tr'de"
        //Element
        Image.content       = ""
        //Element
        URL.content         = "https://erkaklima.com.tr/"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "LG Klima ve Hava Temizleme Ürünleri - Erkaklima.com.tr'de"
        //Element
        Description.content = "LG Klima, Inverter Klima, 9000 BTU Klima, 12000 BTU Klima, 18000 BTU Klima, Salon Tipi Klima, VRF Klima ve Klima Fiyatları Erkaklima.com.tr'de"
        //Element
        Image.content       = ""
        //Element
        URL.content         = "https://erkaklima.com.tr/"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "LG Klima ve Hava Temizleme Ürünleri - Erkaklima.com.tr'de"
        //Element
        Description.content = "LG Klima, Inverter Klima, 9000 BTU Klima, 12000 BTU Klima, 18000 BTU Klima, Salon Tipi Klima, VRF Klima ve Klima Fiyatları Erkaklima.com.tr'de"
        //Element
        Image.content       = ""
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "LG Klima ve Hava Temizleme Ürünleri - Erkaklima.com.tr'de"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "LG Klima, Inverter Klima, 9000 BTU Klima, 12000 BTU Klima, 18000 BTU Klima, Salon Tipi Klima, VRF Klima ve Klima Fiyatları Erkaklima.com.tr'de"
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
    }
    //Element
    render() {
        //Element
        const Slider = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            autoplay        : {
                //Element
                delay       : 5000
            },
            //Element
            navigation      : {
                //Element
                nextEl      : ".w181kh",
                //Element
                prevEl      : ".t003r7"
            },
            //Element
            pagination      : {
                //Element
                el          : ".y02vy3",
                //Element
                clickable   : true
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 n91c77" },
            //Element
            React.createElement( "section", { className: "float-start w-100 position-relative o3664v" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 a3250o" },
                    //Element
                    React.createElement( Background, { ID: "0", Title: "LG Electronics", Media: "001.mp4", Target: "/" } ),
                    //Element
                    React.createElement( Figure, {} )
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 h-100 justify-content-center align-items-center p-0 m-0 position-absolute start-0 top-0 aq566j" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Swiper, { className: "float-start w-100 h-100 position-relative overflow-hidden", ...Slider },
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Headline, { ID: "0", Title: "LG DualCool Klimalarla Hızlı Soğutma, Hızlı Konfor", Summary: "LG DUAL Inverter Compressor ile zorlu iklimlerde bile rahat edin.", Media: "", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Headline, { ID: "0", Title: "Daha Güçlü, Tasarruflu Güvenilir Klima", Summary: "LG DUAL Inverter Compressor™ ile daha hızlı ve konforlu soğutma.", Media: "", Target: "/" } )
                                ),
                                //Element
                                React.createElement( SwiperSlide, {},
                                    //Element
                                    React.createElement( Headline, { ID: "0", Title: "Daha Verimli, Daha Hızlı DUAL Inverter Teknoloji.", Summary: "Daha Verimli, Daha Hızlı, DUAL Inverter Teknoloji", Media: "", Target: "/" } )
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-absolute end-0 top-0 v258gv" },
                                //Element
                                React.createElement( Container, {},
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:12 },
                                            //Element
                                            React.createElement( Paginate, {} )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 k6037f" },
                //Element
                React.createElement( "div", { className: "float-start w-25 p-0 m-0 q4570i" },
                    //Element
                    React.createElement( Setup, { ID: "0", Title: "Hızlı ve Kolay Kurulum", Summary: "Kurulumu daha zahmetsiz olan klimalarla serinliğin tadını anında çıkarmaya başlayabilirsiniz.", Target: "/" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-25 p-0 m-0 qn874y" },
                    //Element
                    React.createElement( Energy, { ID: "0", Title: "Enerji Faturalarından Tasarruf Edin", Summary: "Elektrik faturanızı azaltmak için daha verimli bir soğutma sistemine geçerek enerji tüketiminizi düşürebilirsiniz.", Target: "/" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-25 p-0 m-0 q4570i" },
                    //Element
                    React.createElement( Smart, { ID: "0", Title: "Akıllı Kontrol, Daha Akıllı Yaşam", Summary: "ThinQ uygulaması, klimanıza benzersiz bir erişim sağlayarak daha önce karşılaşmadığınız bağlantı kolaylığı sunar.", Target: "/" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-25 p-0 m-0 qn874y" },
                    //Element
                    React.createElement( Guarantee, { ID: "0", Title: "10 Yıl Garanti", Summary: "Bir kompresörün 10 yıl garantiyle sunulması, en üst düzey performansını daha uzun süre koruyacağını gösterir.", Target: "/" } )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 snf660" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 y8i3b3" },
                                //Element
                                React.createElement( "h3", { className: "float-start w-100 p-0 m-0 text-center lh-1 u6y50s", title: "LG Klima ve Isıtma Sistemleri" },
                                    //Element
                                    "LG Klima ve Isıtma Sistemleri"
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 snf660" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Product, { ID: "AC12BH", Title: "ARTCOOL Inverter Wi-Fi 12.000 BTU Duvar Tipi Klima", Price: "48.899.00 TL", Media: "Z2.jpg", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( Featured, { ID: "0", Title: "LG Electronics", Media: "003.mp4", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Product, { ID: "AC18BH", Title: "ARTCOOL Inverter Wi-Fi 18.000 BTU Duvar Tipi Klima", Price: "57.898.99 TL", Media: "", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Product, { ID: "PC09SQ", Title: "DUALCOOL Plus Inverter Wi-Fi 9.000 BTU Duvar Tipi Klima", Price: "30.299.00 TL", Media: "", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Product, { ID: "PC12SQ", Title: "DUALCOOL Plus Inverter Wi-Fi 12.000 BTU Duvar Tipi Klima", Price: "31.599.01 TL", Media: "", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Product, { ID: "PC18SQ", Title: "DUALCOOL Plus Inverter Wi-Fi 18.000 BTU Duvar Tipi Klima", Price: "43.899.00 TL", Media: "", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Product, { ID: "AC24BH", Title: "ARTCOOL Inverter™ Wi-Fi 24.000 BTU Duvar Tipi Klima", Price: "66.298.99 TL", Media: "", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Product, { ID: "APH48GTLA0", Title: "LG Monofaze 48.000 BTU Inverter Salon Tipi Klima", Price: "115.498.99 TL", Media: "", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Product, { ID: "ZTW48GALA1", Title: "LG Dual Vane 48.000 BTU Inverter Kaset Tipi Klima", Price: "101.848.00 TL", Media: "", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Product, { ID: "ZTW60GALA1", Title: "LG Dual Vane 60.000 BTU Inverter Kaset Tipi Klima", Price: "107.601.00 TL", Media: "", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Product, { ID: "AHBW126AO", Title: "LG Therma R32 S Monoblok 16.000 BTU Inverter Isı Pompası", Price: "185.280.00 TL", Media: "", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 snf660" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 y8i3b3" },
                                //Element
                                React.createElement( "h4", { className: "float-start w-100 p-0 m-0 text-center lh-1 u6y50s", title: "Kampanyalar" },
                                    //Element
                                    "Kampanyalar"
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 qo482c" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Campaign, { ID: "0", Title: "Lansmana Özel LG Artcool Klimalar'da Sepette 1.000 TL İndirim Erkaklima.com.tr'de!", Summary: "LG 18.000 BTU Artcool Klimalar Sepette 1.000 TL İndirim Fırsatı Erkaklima.com.tr'de.", Media: "", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 snf660" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 y8i3b3" },
                                //Element
                                React.createElement( "h5", { className: "float-start w-100 p-0 m-0 text-center lh-1 u6y50s", title: "Hava Temizleme Cihazları" },
                                    //Element
                                    "Hava Temizleme Cihazları"
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 snf660" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Tower, { ID: "AS10GDWH0", Title: "LG PuriCare™ 360° Air Purifier Single Hava Temizleme Cihazı", Price: "19.464.00 TL", Media: "", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( Popular, { ID: "0", Title: "LG Electronics", Media: "004.mp4", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Tower, { ID: "AS65GDWH0", Title: "LG PuriCare™ 360° Air Purifier Double Hava Temizleme Cihazı", Price: "19.899.00 TL", Media: "", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Tower, { ID: "PFPADA01", Title: "360° Yağ Buharı Özel Ön Filtre", Price: "824.00 TL", Media: "", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Tower, { ID: "PFSDNC01", Title: "360° H13 HEPA Filtre", Price: "1.149.49 TL", Media: "", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Tower, { ID: "PFPDNC06", Title: "360° Yıkanabilir Ön Filtre", Price: "749.50 TL", Media: "", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Tower, { ID: "PFPACA01", Title: "360° Sigara Dumanı Özel Ön Filtre", Price: "824.00 TL", Media: "", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 snf660" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 y8i3b3" },
                                //Element
                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-center lh-1 u6y50s", title: "Beraber Çözelim" },
                                    //Element
                                    "Beraber Çözelim"
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 pt-5 pb-5 m-0 mt-5 bt779q" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( Service, { ID: "0", Title: "Montaj Talebi", Summary: "Kaliteli klima montajıyla uzun süreli konforunuzu garanti altına alın ve güvenle kullanın.", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( Service, { ID: "0", Title: "Bakım Talebi", Summary: "Klimanızın performansını artırmak ve uzun ömürlü kullanım sağlamak için bakım zamanı geldi.", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( Service, { ID: "0", Title: "Sorun Giderme", Summary: "Cihazınızın optimum performansını sürdürebilmesi için bakım ve onarım gerekebilir.", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 snf660" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 y8i3b3" },
                                //Element
                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-center lh-1 u6y50s", title: "Blog" },
                                    //Element
                                    "Blog"
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 c16yf4" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Blog, { ID: "0", Title: "LG Klima Kumanda Kullanımı", Date: "01.01.2024", Media: "", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Blog, { ID: "0", Title: "LG Klima Filtre Temizliği Nasıl Yapılır?", Date: "01.01.2024", Media: "", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Blog, { ID: "0", Title: "LG Klima Bakımı Nasıl Yapılır?", Date: "01.01.2024", Media: "", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Blog, { ID: "0", Title: "LG Klima Gece Moduna Nasıl Alınır?", Date: "01.01.2024", Media: "", Target: "/" } )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Main;
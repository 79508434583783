//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as Phone } from "../../../Media/Icon/016.svg";
//Install
import { ReactComponent as WhatsApp } from "../../../Media/Icon/017.svg";

//Element
class Default extends Component {
    //Element
    componentDidMount(){
        //Element
        let Button = document.getElementsByClassName( "j684p1" )[0]
        //Element
        window.addEventListener( "scroll", () => {
            //Element
            if( document.documentElement.scrollTop > 700 ){
                //Element
                Button.classList.remove( "d-none" )
            //Element
            } else {
                //Element
                Button.classList.add( "d-none" )
            }
        })
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-auto p-0 m-0 d-none j684p1" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "d-flex w-auto flex-column justify-content-center align-items-end p-0 m-0 position-fixed v500i2" },
                            //Element
                            React.createElement( "button", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 mb-2 position-relative fs7d60", type: "button" },
                                //Element
                                React.createElement( Phone, {} ),
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center position-absolute pi55p2" },
                                    //Element
                                    "Hemen ara"
                                )
                            ),
                            //Element
                            React.createElement( "button", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 mb-2 position-relative w269ju", type: "button" },
                                //Element
                                React.createElement( WhatsApp, {} ),
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-center position-absolute s9871j" },
                                    //Element
                                    "WhatsApp'a Bağlan"
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;
//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Search } from "../../Media/Icon/003.svg";
//Install
import { ReactComponent as User } from "../../Media/Icon/002.svg";
//Install
import { ReactComponent as Cart } from "../../Media/Icon/001.svg";

//Element
class SET extends Component {
    //Element
    Toggle = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Backdrop = document.getElementsByClassName( "w3s37q" )[0]
        //Element
        let Search   = document.getElementsByClassName( "w42q6d" )[0]
        //Element
        Search.classList.toggle( "visible" )
        //Element
        Backdrop.classList.toggle( "invisible" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 position-relative izl824" },
            //Element
            React.createElement( "button", { id: "Web_Search_0", name: "Web_Search_0", className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 bg-transparent border-0 a82t7y", type: "button", onClick: this.Toggle },
                //Element
                React.createElement( Search, {} )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-auto justify-content-center align-items-center p-0 ps-1 m-0 ms-4 bg-transparent border-0 position-relative a142pz" },
                //Element
                React.createElement( User, {} ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-absolute ty22m7" },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 text-center r28345" },
                        //Element
                        "Merhaba"
                    ),
                    //Element
                    React.createElement( "ul", { className: "float-start w-100 p-0 ps-4 pe-4 pt-2 pb-2 m-0 h333i8" },
                        //Element
                        React.createElement( "li", { className: "float-start w-100 position-relative" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                //Element
                                "Hemen Hesap Oluştur"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100 position-relative" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                //Element
                                "Giriş Yap"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100 position-relative" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                //Element
                                "Şifremi Unuttum"
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "button", { id: "Web_Cart_0", name: "Web_Cart_0", className: "d-flex w-auto justify-content-center align-items-center p-0 ps-1 m-0 ms-4 bg-transparent border-0 j56448", type: "button" },
                //Element
                React.createElement( Cart, {} ),
                //Element
                React.createElement( "span", { className: "d-flex w-100 justify-content-center align-items-center position-absolute rounded-circle text-center x3q2c6" },
                    //Element
                    "0"
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default SET;
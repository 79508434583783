//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Headline extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Media, Target } = this.props
        //Element
        let Source = ""
        //Element
        let Image  = Source + Media
        //Element
        const Content = React.createElement( "a", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 position-relative r5m3s3", title: Title, href: Target },
            //Element
            React.createElement( "h2", { className: "float-start w-auto p-0 m-0 position-relative e9584q" },
                //Element
                Title
            ),
            //Element
            React.createElement( "label", { className: "float-start w-100 p-0 m-0 mt-3 position-relative x6x21h" },
                //Element
                Summary
            ),
            //Element
            React.createElement( "span", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-4 position-relative a73e58" },
                //Element
                "Hemen Bakın"
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Headline.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Media   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Headline;
//Install
import React, { Component } from "react";
//Install
import { ReactComponent as U246I9 } from "../../Media/Icon/009.svg";
//Install
import propTypes from "prop-types";

//Element
class Service extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Target } = this.props
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-5 m-0 mt-4 mb-4 v04h1j", href: Target },
            //Element
            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 dz1298" },
                //Element
                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 j47l6a", title: Title },
                    //Element
                    Title
                ),
                //Element
                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-2 mb-3 qde061" },
                    //Element
                    Summary
                ),
                //Element
                React.createElement( "i", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 s5re57" },
                    //Element
                    React.createElement( U246I9, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Service.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Service;
//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Icon } from "../../Media/Icon/000.svg";

//Element
class Brand extends Component {
    //Element
    N346EL = (e) => {
        //Element
        e.preventDefault()
        //Element
        window.location.href = "/"
    }
    //Element
    render() {
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 u3505r" },
            //Element
            React.createElement( "h1", { className: "float-start w-auto p-0 m-0", title: "LG Klima" },
                //Element
                React.createElement( "a", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 bg-transparent border-0 g551a7", title: "LG Klima", href: "/" },
                    //Element
                    React.createElement( Icon, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Brand;
//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import propTypes from "prop-types";

//Element
class Campaign extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Media, Target } = this.props
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 i2b395", title: Title, href: Target },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "picture", { className: "float-start w-100 p-0 m-0 v6591c" } )
                ),
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "header", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 ps-4 m-0 e2l151" },
                        //Element
                        React.createElement( "h4", { className: "float-start w-100 p-0 m-0 x17320" },
                            //Element
                            Title
                        ),
                        //Element
                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 z69b30" },
                            //Element
                            Summary
                        ),
                        //Element
                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center o77kb6" },
                            //Element
                            "Hemen Bakın"
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Campaign.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Media   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Campaign;
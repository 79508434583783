//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";

//Element
class Menu extends Component {
    //Element
    J4P39W = (e) => {
        //Element
        e.preventDefault()
        //Element
        let XSC670 = document.getElementsByClassName( "h827np" )[0]
        //Element
        let L4UP68 = document.getElementsByClassName( "s68c45" )[0]
        //Element
        let Y1SO78 = document.querySelectorAll( ".b247gl li a" )
        //Element
        XSC670.classList.add( "gb366i" )
        //Element
        L4UP68.classList.remove( "d-none" )
        //Element
        for (let Index = 0; Index < Y1SO78.length; Index++) {
            //Element
            Y1SO78[Index].classList.add( "vOx602" )
        }
    }
    //Element
    J54F66 = (e) => {
        //Element
        e.preventDefault()
        //Element
        let XSC670 = document.getElementsByClassName( "h827np" )[0]
        //Element
        let L4UP68 = document.getElementsByClassName( "s68c45" )[0]
        //Element
        L4UP68.classList.add( "d-none" )
        //Element
        XSC670.classList.remove( "gb366i" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "menu", { className: "float-start w-100 p-0 m-0 pq33z1" },
            //Element
            React.createElement( "ul", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 b247gl" },
                //Element
                React.createElement( "li", { className: "float-start w-auto position-relative" },
                    //Element
                    React.createElement( "a", { className: "d-flex w-auto justify-content-start align-items-center position-relative", href: "/" },
                        //Element
                        "LG Klima"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto position-relative" },
                    //Element
                    React.createElement( "a", { className: "d-flex w-auto justify-content-start align-items-center position-relative", href: "/" },
                        //Element
                        "Kurumsal"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto" },
                    //Element
                    React.createElement( "a", { className: "d-flex w-auto justify-content-start align-items-center", href: "/", onMouseOver: this.J4P39W, onMouseOut: this.J54F66 },
                        //Element
                        "Ürünler"
                    ),
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-absolute d-none s68c45", onMouseOver: this.J4P39W, onMouseOut: this.J54F66 },
                        //Element
                        React.createElement( Container, {},
                            //Element
                            React.createElement( Row, {},
                                //Element
                                React.createElement( Col, { md:2 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 b70r6q" },
                                        //Element
                                        React.createElement( "h1", { className: "float-start w-100 p-0 m-0 mb-3 r7g188" },
                                            //Element
                                            "Klima"
                                        ),
                                        //Element
                                        React.createElement( "ol", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 j2t3c3" },
                                            //Element
                                            React.createElement( "li", { className: "float-start w-auto position-relative" },
                                                //Element
                                                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/" },
                                                    //Element
                                                    "Duvar Tipi Klimalar"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "li", { className: "float-start w-auto position-relative" },
                                                //Element
                                                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/" },
                                                    //Element
                                                    "Salon Tipi Klima"
                                                )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:2 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 b70r6q" },
                                        //Element
                                        React.createElement( "h2", { className: "float-start w-100 p-0 m-0 mb-3 r7g188" },
                                            //Element
                                            "Ticari Klima"
                                        ),
                                        //Element
                                        React.createElement( "ol", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 j2t3c3" },
                                            //Element
                                            React.createElement( "li", { className: "float-start w-auto position-relative" },
                                                //Element
                                                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/" },
                                                    //Element
                                                    "VRF Sistemleri"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "li", { className: "float-start w-auto position-relative" },
                                                //Element
                                                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/" },
                                                    //Element
                                                    "Chiller"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "li", { className: "float-start w-auto position-relative" },
                                                //Element
                                                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/" },
                                                    //Element
                                                    "Multi Split Klima"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "li", { className: "float-start w-auto position-relative" },
                                                //Element
                                                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/" },
                                                    //Element
                                                    "Isıtma Çözümleri"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "li", { className: "float-start w-auto position-relative" },
                                                //Element
                                                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/" },
                                                    //Element
                                                    "Single Split Klima"
                                                )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:2 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 b70r6q" },
                                        //Element
                                        React.createElement( "h3", { className: "float-start w-100 p-0 m-0 mb-3 r7g188" },
                                            //Element
                                            "Hava Bakımı"
                                        ),
                                        //Element
                                        React.createElement( "ol", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 j2t3c3" },
                                            //Element
                                            React.createElement( "li", { className: "float-start w-auto position-relative" },
                                                //Element
                                                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/" },
                                                    //Element
                                                    "Vantilatör"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "li", { className: "float-start w-auto position-relative" },
                                                //Element
                                                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/" },
                                                    //Element
                                                    "Hava Temizleme"
                                                )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:2 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 b70r6q" },
                                        //Element
                                        React.createElement( "h3", { className: "float-start w-100 p-0 m-0 mb-3 r7g188" },
                                            //Element
                                            "LG Electronics"
                                        ),
                                        //Element
                                        React.createElement( "ol", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 j2t3c3" },
                                            //Element
                                            React.createElement( "li", { className: "float-start w-auto position-relative" },
                                                //Element
                                                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/" },
                                                    //Element
                                                    "LG ThinQ"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "li", { className: "float-start w-auto position-relative" },
                                                //Element
                                                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/" },
                                                    //Element
                                                    "LG ile Yaşam"
                                                )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:2 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 b70r6q" },
                                        //Element
                                        React.createElement( "h3", { className: "float-start w-100 p-0 m-0 mb-3 r7g188" },
                                            //Element
                                            "Destek Talebi"
                                        ),
                                        //Element
                                        React.createElement( "ol", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 j2t3c3" },
                                            //Element
                                            React.createElement( "li", { className: "float-start w-auto position-relative" },
                                                //Element
                                                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/" },
                                                    //Element
                                                    "Montaj Talebi"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "li", { className: "float-start w-auto position-relative" },
                                                //Element
                                                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/" },
                                                    //Element
                                                    "Bakım Talebi"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "li", { className: "float-start w-auto position-relative" },
                                                //Element
                                                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/" },
                                                    //Element
                                                    "Sorun Giderme"
                                                )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:2 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 b70r6q" },
                                        //Element
                                        React.createElement( "h3", { className: "float-start w-100 p-0 m-0 mb-3 r7g188" },
                                            //Element
                                            "Müşteri Hizmetleri"
                                        ),
                                        //Element
                                        React.createElement( "ol", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 j2t3c3" },
                                            //Element
                                            React.createElement( "li", { className: "float-start w-auto position-relative" },
                                                //Element
                                                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/" },
                                                    //Element
                                                    "WhatsApp Destek"
                                                )
                                            ),
                                            //Element
                                            React.createElement( "li", { className: "float-start w-auto position-relative" },
                                                //Element
                                                React.createElement( "a", { className: "float-start w-auto position-relative", href: "/" },
                                                    //Element
                                                    "E-posta"
                                                )
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-4 mb-4 fv710l", href: "/" },
                                        //Element
                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 mt-4 position-relative overflow-hidden o308gh" },
                                            //Element
                                            React.createElement( "img", { alt: "Klima Montaj Servisi Bulun", title: "Klima Montaj Servisi Bulun", src: "https://cdn.erkaklima.com.tr/content/001.jpg", width: "306", height: "155" } )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-4 mb-4 fv710l", href: "/" },
                                        //Element
                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 mt-4 position-relative overflow-hidden dd382b" },
                                            //Element
                                            React.createElement( "img", { alt: "Verimli Klima Kullanım Yöntemleri", title: "Verimli Klima Kullanım Yöntemleri", src: "https://cdn.erkaklima.com.tr/content/002.webp", width: "306", height: "155" } ),
                                            //Element
                                            React.createElement( "figcaption", { className: "d-flex w-100 h-100 justify-content-start align-items-center p-0 ps-4 pe-4 m-0 position-absolute start-0 top-0 q450lf" },
                                                //Element
                                                "Verimli Klima Kullanım Yöntemleri"
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-4 mb-4 fv710l", href: "/" },
                                        //Element
                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 mt-4 position-relative overflow-hidden dd382b" },
                                            //Element
                                            React.createElement( "img", { alt: "Klimalar'da İdeal Sıcaklık Ayarlarıı", title: "Klimalar'da İdeal Sıcaklık Ayarları", src: "https://cdn.erkaklima.com.tr/content/003.jpg", width: "306", height: "155" } ),
                                            //Element
                                            React.createElement( "figcaption", { className: "d-flex w-100 h-100 justify-content-start align-items-center p-0 ps-4 pe-4 m-0 position-absolute start-0 top-0 q450lf" },
                                                //Element
                                                "Klimalar'da İdeal Sıcaklık Ayarları"
                                            )
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-4 mb-4 fv710l", href: "/" },
                                        //Element
                                        React.createElement( "figure", { className: "float-start w-100 h-100 p-0 m-0 mt-4 position-relative overflow-hidden dd382b" },
                                            //Element
                                            React.createElement( "img", { alt: "Inverter Klima Kullanmanın Avantajları", title: "Inverter Klima Kullanmanın Avantajları", src: "https://cdn.erkaklima.com.tr/content/004.jpg", width: "306", height: "155" } ),
                                            //Element
                                            React.createElement( "figcaption", { className: "d-flex w-100 h-100 justify-content-start align-items-center p-0 ps-4 pe-4 m-0 position-absolute start-0 top-0 q450lf" },
                                                //Element
                                                "Inverter Klima Kullanmanın Avantajları"
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto position-relative" },
                    //Element
                    React.createElement( "a", { className: "d-flex w-auto justify-content-start align-items-center position-relative", href: "/" },
                        //Element
                        "Servis Randevusu"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto position-relative" },
                    //Element
                    React.createElement( "a", { className: "d-flex w-auto justify-content-start align-items-center position-relative", href: "/" },
                        //Element
                        "Kampanyalar"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto position-relative" },
                    //Element
                    React.createElement( "a", { className: "d-flex w-auto justify-content-start align-items-center position-relative", href: "/" },
                        //Element
                        "Kariyer"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto position-relative" },
                    //Element
                    React.createElement( "a", { className: "d-flex w-auto justify-content-start align-items-center position-relative", href: "/" },
                        //Element
                        "Hemen Ulasin"
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Menu;
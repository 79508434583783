//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Q3VK54 } from "../../../Media/Icon/004.svg";
//Install
import { ReactComponent as P7N09H } from "../../../Media/Icon/005.svg";

//Element
class Bound extends Component {
    //Element
    L4O068 = (e) => {
        //Element
        e.preventDefault()
        //Element
        e.nativeEvent.target.nextElementSibling.classList.toggle( "d-none" )
        //Element
        e.nativeEvent.target.offsetParent.childNodes[0].classList.toggle( "d-none" )
        //Element
        e.nativeEvent.target.offsetParent.childNodes[1].classList.toggle( "d-none" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative q6o401" },
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 f9w0x4" },
                //Element
                React.createElement( Q3VK54, {} )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 d-none ak3390" },
                //Element
                React.createElement( P7N09H, {} )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0 position-absolute yf230d", onClick: this.L4O068 },
                //Element
                "Zorunlu Çerezler"
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 d-none q9r500" },
                //Element
                React.createElement( "p", { className: "float-start w-100 p-3 m-0 s36ba6" },
                    //Element
                    "Belli çerezlerin kullanımı Sitemizin doğru biçimde çalışması için zorunludur. Örneğin, Sitemizde oturum açtığınızda devreye giren ilk kimlik doğrulama çerezleri sitemizde bir sayfadan diğerine geçişinizde etkin olan oturumunuzun devam etmesini sağlamaktadır."
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Bound;
//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Dialog from "./Dialog";
//Install
import Setting from "./Setting";

//Element
class Default extends Component {
    //Element
    X3TS65 = () => {
        //Element
        let R2W347 = document.getElementsByTagName( "body" )[0]
        //Element
        R2W347.classList.add( "overflow-hidden" )
    }
    //Element
    componentDidMount(){
        //Element
        //this.X3TS65()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-fixed start-0 top-0 d-none c6j6q7" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( Dialog, { ID: "0", Title: "Gizlilik Tercihi Merkezi" } ),
                        //Element
                        React.createElement( Setting, {} )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;
//Install
import React, { Component } from "react";
//Install
import Group from "../../Column/Product/Default";
//Install
import { ReactComponent as Icon } from "../../../Media/Icon/008.svg";
//Install
import propTypes from "prop-types";

//Element
class Default extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Price, Media, Target } = this.props
        //Element
        let Source = "https://www.lg.com/content/dam/channel/wcms/tr/images/klima/ac12bh_nsj_emtk_tr_c/gallery/"
        //Element
        let Photo  = Source + Media
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-4 pt-3 pb-3 m-0 mt-5 w31jj7", href: Target },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative e699av" },
                //Element
                //React.createElement( "img", { alt: Title, title: Title, src: Photo, width: "256", height: "172" } ),
                //Element
                React.createElement( Group, { ID: "0", Title: "", Target: "/" } )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 d415fs" },
                //Element
                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center lh-1 b667t7" },
                    //Element
                    ID
                ),
                //Element
                React.createElement( "h3", { className: "float-start w-100 p-0 m-0 mt-2 text-center c35m02" },
                    //Element
                    Title
                ),
                //Element
                React.createElement( "i", { className: "float-start w-100 p-0 m-0 mt-2 text-center on389s" },
                    //Element
                    React.createElement( Icon, {} )
                ),
                //Element
                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 text-center qq906n" },
                    //Element
                    Price
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Default.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Price  : propTypes.string.isRequired,
    Media  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Default;
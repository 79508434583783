//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Q3VK54 } from "../../../Media/Icon/004.svg";
//Install
import { ReactComponent as P7N09H } from "../../../Media/Icon/005.svg";

//Element
class Performance extends Component {
    //Element
    L4O068 = (e) => {
        //Element
        e.preventDefault()
        //Element
        e.nativeEvent.target.offsetParent.lastChild.classList.toggle( "d-none" )
        //Element
        e.nativeEvent.target.offsetParent.childNodes[0].classList.toggle( "d-none" )
        //Element
        e.nativeEvent.target.offsetParent.childNodes[1].classList.toggle( "d-none" )
    }
    //Element
    B3D967 = (e) => {
        //Element
        e.preventDefault()
        //Element
        e.nativeEvent.srcElement.classList.toggle( "f3933g" )
        //Element
        e.nativeEvent.srcElement.classList.toggle( "justify-content-end" )
        //Element
        console.log( e.nativeEvent.srcElement.children[0].classList.toggle( "float-end" ) )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative q6o401" },
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 f9w0x4" },
                //Element
                React.createElement( Q3VK54, {} )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 d-none ak3390" },
                //Element
                React.createElement( P7N09H, {} )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 position-absolute x4w13i", onClick: this.L4O068 },
                //Element
                "Performans Tanımlama Bilgileri"
            ),
            //Element
            React.createElement( "div", { className: "d-flex justify-content-end align-items-center p-0 pe-3 m-0 position-absolute end-0 top-0 h81e2y" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 ps-1 pe-1 m-0 qa141i", onClick: this.B3D967 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 y15732" } )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 d-none q9r500" },
                //Element
                React.createElement( "p", { className: "float-start w-100 p-3 pb-1 m-0 s36ba6" },
                    //Element
                    "Bu tanımlama bilgileri, sitemizin performansını ölçebilmemiz ve iyileştirebilmemiz için sitenin ziyaret edilme sayısını ve trafik kaynaklarını sayabilmemizi sağlar."
                ),
                //Element
                React.createElement( "p", { className: "float-start w-100 p-3 pt-0 pb-1 m-0 s36ba6" },
                    //Element
                    "Hangi sayfaların en fazla ve en az ziyaret edildiğini ve ziyaretçilerin sitede nasıl gezindiklerini öğrenmemize yardımcı olurlar. Bu tanımlama bilgilerinin topladığı tüm bilgiler derlenir ve bu nedenle anonimdir."
                ),
                //Element
                React.createElement( "p", { className: "float-start w-100 p-3 pt-0 m-0 s36ba6" },
                    //Element
                    "Bu tanımlama bilgilerine izin vermezseniz sitemizi ne zaman ziyaret ettiğinizi bilemeyiz."
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Performance;
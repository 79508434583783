//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";

//Element
class Menu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 i248mz" },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 v5g27d" },
                        //Element
                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 text-truncate d607c1" },
                            //Element
                            "Genel Bilgilendirme"
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 f6932i" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "Kurumsal"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "Manifesto"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "Kampanyalar"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "Kimlere Hizmet Sunuldu?"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "Hemen Ulasin"
                                )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 v5g27d" },
                        //Element
                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 text-truncate d607c1" },
                            //Element
                            "Kurumsal Değerler"
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 f6932i" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "Erişilebilirlik"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "Çevre"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "Gizlilik"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "Tedarik Zinciri"
                                )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 v5g27d" },
                        //Element
                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 text-truncate d607c1" },
                            //Element
                            "Ürünler"
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 f6932i" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "Klima"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "Hava Bakımı"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "Ticari Klimalar"
                                )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 v5g27d" },
                        //Element
                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 text-truncate d607c1" },
                            //Element
                            "Servis Randevusu"
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 f6932i" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "Montaj"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "Bakım"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "Sorun Giderme"
                                )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-1 v5g27d" },
                        //Element
                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 text-truncate d607c1" },
                            //Element
                            "Müşteri Hizmetleri"
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 f6932i" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "WhatsApp Destek"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "E-posta"
                                )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-1 v5g27d" },
                        //Element
                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 text-truncate d607c1" },
                            //Element
                            "Daha Fazlası"
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 f6932i" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "Blog"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "Sıkça Sorulan Sorular"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "Ücretsiz Keşif Başvurusu"
                                )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-1 v5g27d" },
                        //Element
                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 text-truncate d607c1" },
                            //Element
                            "Kişisel Verilerin Korunması"
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 f6932i" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "Kişisel Verileri Korunma Politikası"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "Çerez Politikaları"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 position-relative", href: "/" },
                                    //Element
                                    "Başvuru Formu"
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Menu;
//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Icon } from "../../../Media/Icon/009.svg";
//Install
import propTypes from "prop-types";

//Element
class Default extends Component {
    //Element
    render() {
        //Element
        const { ID, Brand, Sub, Group, Product, Target } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 je635q" },
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0", title: Brand, href: "/" },
                    //Element
                    Brand
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "i", { className: "float-start w-auto p-0 m-0 ms-2" },
                    //Element
                    React.createElement( Icon, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0 ms-2", title: Sub, href: Group },
                    //Element
                    Sub
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "i", { className: "float-start w-auto p-0 m-0 ms-2" },
                    //Element
                    React.createElement( Icon, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0 ms-2", title: Product, href: Target },
                    //Element
                    Product
                )
            ),
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Default.propTypes = {
    ID       : propTypes.string.isRequired,
    Sub      : propTypes.string.isRequired,
    Group    : propTypes.string.isRequired,
    Brand    : propTypes.string.isRequired,
    Product  : propTypes.string.isRequired,
    Target   : propTypes.string.isRequired
}
//Element
export default Default;
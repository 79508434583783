//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as Search } from "../../../Media/Icon/019.svg";
//Install
import { ReactComponent as Close } from "../../../Media/Icon/020.svg";

//Element
class Default extends Component {
    //Element
    Toggle = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Backdrop = document.getElementsByClassName( "w3s37q" )[0]
        //Element
        let Search   = document.getElementsByClassName( "w42q6d" )[0]
        //Element
        Search.classList.toggle( "visible" )
        //Element
        Backdrop.classList.toggle( "invisible" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-fixed start-0 top-0 w42q6d" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 w21474" },
                            //Element
                            React.createElement( "form", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative ox83u7" },
                                //Element
                                React.createElement( "input", { id: "Web_Header_Search", name: "Web_Header_Search", className: "float-start w-100 position-relative s37s70", placeholder: "Hemen Arama Yapın", type: "text", required: "required" } ),
                                //Element
                                React.createElement( "button", { id: "Web_Header_Search_Btn", name: "Web_Header_Search_Btn", className: "float-start w-100 p-0 m-0 bg-transparent border-0 position-absolute c02j84", type: "submit" },
                                    //Element
                                    React.createElement( Search, {} )
                                ),
                                //Element
                                React.createElement( "button", { id: "Web_Header_Close_Btn", name: "Web_Header_Close_Btn", className: "float-start w-100 p-0 m-0 bg-transparent border-0 position-absolute start-0 top-0 m87bg8", type: "submit", onClick: this.Toggle },
                                    //Element
                                    React.createElement( Close, {} )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;
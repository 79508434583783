//Install
import React, { Component } from "react";

//Element
class Backdrop extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-fixed start-0 top-0 invisible w3s37q" } )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Backdrop;
//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Breadcrumb from "../../Column/Breadcrumb/Default";
//Install
import { ReactComponent as Icon } from "../../../Media/Icon/022.svg";
//Install
import { ReactComponent as Arrow } from "../../../Media/Icon/023.svg";

//Element
class Single extends Component {
    //Element
    Toggle = (e) => {
        //Element
        let Column = document.querySelectorAll( ".sf863s" )
        //Element
        for (let Index = 0; Index < Column.length; Index++) {
            //Element
            Column[Index].classList.add( "d-none" )
        }
        //Element
        e.target.nextSibling.classList.remove( "d-none" )
    }
    //Element
    Header = () => {
        //Element
        let Column = document.getElementsByClassName( "tp4h92" )[0]
        //Element
        Column.classList.add( "gb366i" )
    }
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "LG Single Split Klimalar - Erkaklima.com.tr'de"
        //Element
        Description.content = "LG Single Split klimalar çeşitli mağazaları için ideal seçimdir. Çok çeşitli iç üniteleriyle uyumluluğu sayesinde mekanınızın konforunu yükseltir ve optimum enerji verimliliği ile güvenilir performans sunar."
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/upload/business/single-split/001.webp"
        //Element
        URL.content         = "https://erkaklima.com.tr/ticari-klima/ticari-split-klimalar"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "LG Single Split Klimalar - Erkaklima.com.tr'de"
        //Element
        Description.content = "LG Single Split klimalar çeşitli mağazaları için ideal seçimdir. Çok çeşitli iç üniteleriyle uyumluluğu sayesinde mekanınızın konforunu yükseltir ve optimum enerji verimliliği ile güvenilir performans sunar."
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/upload/business/single-split/001.webp"
        //Element
        URL.content         = "https://erkaklima.com.tr/ticari-klima/ticari-split-klimalar"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "LG Single Split Klimalar - Erkaklima.com.tr'de"
        //Element
        Description.content = "LG Single Split klimalar çeşitli mağazaları için ideal seçimdir. Çok çeşitli iç üniteleriyle uyumluluğu sayesinde mekanınızın konforunu yükseltir ve optimum enerji verimliliği ile güvenilir performans sunar."
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/upload/business/single-split/001.webp"
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "LG Single Split Klimalar - Erkaklima.com.tr'de"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "LG Single Split klimalar çeşitli mağazaları için ideal seçimdir. Çok çeşitli iç üniteleriyle uyumluluğu sayesinde mekanınızın konforunu yükseltir ve optimum enerji verimliliği ile güvenilir performans sunar."
        //Element
        this.Header()
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 j70q04" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 g9l5m9" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Breadcrumb, { ID: "0", Brand: "LG Klima", Sub: "Ticari Klimalar",  Group: "/ticari-klima", Product: "Single Split Klima", Target: "/ticari-klima/ticari-split-klimalar" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 o8005b" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 pt-5 pb-5 m-0 os365t" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 pt-5 pb-5 m-0 mt-5 mb-5 a51q9f" },
                                    //Element
                                    React.createElement( "h2", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 m6293u", title: "Single Split Klima" },
                                        //Element
                                        "Single Split Klima"
                                    ),
                                    //Element
                                    React.createElement( "p", { className: "float-start w-auto p-0 pt-2 m-0 text-center pf114d" },
                                        //Element
                                        "Single Split klimalar çeşitli mağazaları için ideal seçimdir. Çok çeşitli iç üniteleriyle uyumluluğu sayesinde mekanınızın konforunu yükseltir ve optimum enerji verimliliği ile güvenilir performans sunar."
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 e171gb" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md: "10 offset-md-1" },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-4 mb-4 mk7854" },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 p0z52t" },
                                    //Element
                                    React.createElement( "picture", { className: "float-start w-100 p-0 m-0 le855o" },
                                        //Element
                                        React.createElement( "img", { alt: "Single Split Klima", title: "Single Split Klima", src: "https://cdn.erkaklima.com.tr/upload/business/single-split/001.webp", width: "860", height: "auto" } )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 pt-5 pb-5 m-0 ws229r" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 aq43w1" },
                                //Element
                                React.createElement( "h4", { className: "float-start w-auto p-0 m-0 a87y39" },
                                    //Element
                                    "Temel Özellikler"
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 d97710" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 h-100 p-0 m-0 z170es" },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 zr3056" },
                                    //Element
                                    React.createElement( "h2", { className: "float-start w-100 p-0 m-0 ie3620" },
                                        //Element
                                        "Esnek Uyumluluk"
                                    ),
                                    //Element
                                    React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 qa003m" },
                                        //Element
                                        "Bir dış ünitesi LG'nin çok çeşitli iç üniteleriyle 1+1 olarak kusursuz bağlantı sağlayarak mekanınızın benzersiz ve konforlu atmosferini zenginleştiren farklı estetik tasarım seçeneklerini mümkün kılar."
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 z170es" },
                                //Element
                                React.createElement( "picture", { className: "float-start w-100 p-0 m-0 pv4n82" },
                                    //Element
                                    React.createElement( "img", { alt: "Esnek Uyumluluk", title: "Esnek Uyumluluk", src: "https://cdn.erkaklima.com.tr/upload/business/single-split/003.webp", width: "100%", height: "auto" } )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 z170es" },
                                //Element
                                React.createElement( "picture", { className: "float-start w-100 p-0 m-0 pv4n82" },
                                    //Element
                                    React.createElement( "img", { alt: "Enerji Tasarrufu", title: "Enerji Tasarrufu", src: "https://cdn.erkaklima.com.tr/upload/business/single-split/004.webp", width: "100%", height: "auto" } )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 h-100 p-0 m-0 z170es" },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-end p-0 m-0 zr3056" },
                                    //Element
                                    React.createElement( "h2", { className: "float-start w-auto p-0 m-0 ie3620" },
                                        //Element
                                        "Enerji Tasarrufu"
                                    ),
                                    //Element
                                    React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 text-end qa003m" },
                                        //Element
                                        "Geleneksel kompresörlerin aksine, LG Smart Inverter Kompresör hava akışını akıllı bir şekilde ayarlayarak dış koşullar nasıl olursa olsun optimum konfor ve enerji tasarrufu sağlar."
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 h-100 p-0 m-0 z170es" },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 zr3056" },
                                    //Element
                                    React.createElement( "h2", { className: "float-start w-100 p-0 m-0 ie3620" },
                                        //Element
                                        "Güvenilirlik"
                                    ),
                                    //Element
                                    React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 qa003m" },
                                        //Element
                                        "Single Split Sistemin güçlü performansı düzensiz güç kaynağı ve şiddetli hava durumları gibi zor koşullar altında bile sorunsuz sürdürür."
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 z170es" },
                                //Element
                                React.createElement( "picture", { className: "float-start w-100 p-0 m-0 pv4n82" },
                                    //Element
                                    React.createElement( "img", { alt: "Güvenilirlik", title: "Güvenilirlik", src: "https://cdn.erkaklima.com.tr/upload/business/single-split/005.webp", width: "100%", height: "auto" } )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 z170es" },
                                //Element
                                React.createElement( "picture", { className: "float-start w-100 p-0 m-0 pv4n82" },
                                    //Element
                                    React.createElement( "img", { alt: "LG ThinQ", title: "LG ThinQ", src: "https://cdn.erkaklima.com.tr/upload/business/single-split/006.webp", width: "100%", height: "auto" } )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 h-100 p-0 m-0 z170es" },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-end p-0 m-0 zr3056" },
                                    //Element
                                    React.createElement( "h2", { className: "float-start w-auto p-0 m-0 ie3620" },
                                        //Element
                                        "LG ThinQ"
                                    ),
                                    //Element
                                    React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 text-end qa003m" },
                                        //Element
                                        "Uzaktan ev yönetiminin özgürlüğünü deneyimleyin. LG ThinQ™ uygulamasının sunduğu kolay sıcaklık kontrolü ve anlık enerji tüketimi izleme özellikleriyle evinizin verimliliğini optimize ediyor."
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 pt-5 pb-5 m-0 ws229r" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 aq43w1" },
                                //Element
                                React.createElement( "h4", { className: "float-start w-auto p-0 m-0 a87y39" },
                                    //Element
                                    "Ürün Serisi"
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "a", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x153h1", href: "/" },
                                //Element
                                React.createElement( "picture", { className: "float-start w-100 p-0 m-0 zh621n" },
                                    //Element
                                    React.createElement( "img", { alt: "4 Yön Kaset", title: "4 Yön Kaset", src: "https://cdn.erkaklima.com.tr/upload/business/single-split/007.webp", width: "240", height: "240" } )
                                ),
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-uppercase vi6467" },
                                    //Element
                                    "4 Yön Kaset",
                                    //Element
                                    React.createElement( Arrow, {} )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "a", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x153h1", href: "/" },
                                //Element
                                React.createElement( "picture", { className: "float-start w-100 p-0 m-0 zh621n" },
                                    //Element
                                    React.createElement( "img", { alt: "Dairesel Kaset", title: "Dairesel Kaset", src: "https://cdn.erkaklima.com.tr/upload/business/single-split/008.webp", width: "240", height: "240" } )
                                ),
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-uppercase vi6467" },
                                    //Element
                                    "Dairesel Kaset",
                                    //Element
                                    React.createElement( Arrow, {} )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "a", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x153h1", href: "/" },
                                //Element
                                React.createElement( "picture", { className: "float-start w-100 p-0 m-0 zh621n" },
                                    //Element
                                    React.createElement( "img", { alt: "Dual Vane Kaset", title: "Dual Vane Kaset", src: "https://cdn.erkaklima.com.tr/upload/business/single-split/009.webp", width: "240", height: "240" } )
                                ),
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-uppercase vi6467" },
                                    //Element
                                    "Dual Vane Kaset",
                                    //Element
                                    React.createElement( Arrow, {} )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "a", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x153h1", href: "/" },
                                //Element
                                React.createElement( "picture", { className: "float-start w-100 p-0 m-0 zh621n" },
                                    //Element
                                    React.createElement( "img", { alt: "Tavan Tipi Gizli Kanal", title: "Tavan Tipi Gizli Kanal", src: "https://cdn.erkaklima.com.tr/upload/business/single-split/010.webp", width: "240", height: "240" } )
                                ),
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-uppercase vi6467" },
                                    //Element
                                    "Tavan Tipi Gizli Kanal",
                                    //Element
                                    React.createElement( Arrow, {} )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( "a", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x153h1", href: "/" },
                                //Element
                                React.createElement( "picture", { className: "float-start w-100 p-0 m-0 zh621n" },
                                    //Element
                                    React.createElement( "img", { alt: "Salon Tipi", title: "Salon Tipi", src: "https://cdn.erkaklima.com.tr/upload/business/single-split/007.webp", width: "240", height: "240" } )
                                ),
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-uppercase vi6467" },
                                    //Element
                                    "Salon Tipi",
                                    //Element
                                    React.createElement( Arrow, {} )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 pt-5 pb-5 m-0 ws229r" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 aq43w1" },
                                //Element
                                React.createElement( "h6", { className: "float-start w-auto p-0 m-0 a87y39" },
                                    //Element
                                    "Sık Sorulan Sorular"
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md: "8 offset-md-2" },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mb-5 equ096" },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-5 pt-4 pb-4 m-0 a264s6" },
                                    //Element
                                    React.createElement( "button", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 bg-transparent border-0 v928ih", type: "button", onClick: this.Toggle },
                                        //Element
                                        React.createElement( "span", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0 c3x53z" },
                                            //Element
                                            "Inverter klima nedir ve nasıl çalışır?",
                                            //Element
                                            React.createElement( Icon, {} )
                                        ),
                                        //Element
                                        React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 pt-2 m-0 d-none sf863s" },
                                            //Element
                                            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-1 text-start" },
                                                //Element
                                                "Inverter klima, sıcaklığı düzenlemek için kompresörün motor hızını ayarlayabilir. Sabit hızlı kompresöre kıyasla, değişken hızlı kompresör daha yüksek enerji verimliliği ve daha düşük gürültü özellikleriyle tanınır."
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( "button", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 bg-transparent border-0 v928ih", type: "button", onClick: this.Toggle },
                                        //Element
                                        React.createElement( "span", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0 c3x53z" },
                                            //Element
                                            "Fin teknolojisi nedir ve ne kadar önemlidir?",
                                            //Element
                                            React.createElement( Icon, {} )
                                        ),
                                        //Element
                                        React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 pt-2 m-0 d-none sf863s" },
                                            //Element
                                            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-1 text-start" },
                                                //Element
                                                "Fin teknolojisi gelişmiş korozyon direnci için tasarlanmıştır ve klimanın kondenser bobinlerini korozyon ve pasa karşı korur. Aşağıdaki teknolojiler klimaların soğutma verimliliğini ve ömrünü önemli ölçüde artırır."
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-1 text-start" },
                                                //Element
                                                "Gold Fin teknolojisi: Isı eşanjörü kanadına uygulanan özel altın rengi korozyon önleyici kaplamadır. Gold Fin teknolojisi ısı eşanjörünün dayanıklılığını artırır ve yüzeyinin korozyona karşı daha uzun süre dayanmasını sağlar."
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-1 text-start" },
                                                //Element
                                                "Black Fin teknolojisi: Klimanın harici ünitelerine uygulanan geliştirilmiş epoksi reçineli siyah kaplamadır. Tuz kirliliği ve duman dahil hava kirliliği gibi çeşitli aşındırıcı dış koşullara karşı güçlü bir koruma sağlar."
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( "button", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 bg-transparent border-0 v928ih", type: "button", onClick: this.Toggle },
                                        //Element
                                        React.createElement( "span", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0 c3x53z" },
                                            //Element
                                            "Single Split Sistem bakım gerektirir mi?",
                                            //Element
                                            React.createElement( Icon, {} )
                                        ),
                                        //Element
                                        React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 pt-2 m-0 d-none sf863s" },
                                            //Element
                                            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-1 text-start" },
                                                //Element
                                                "Single Split Sistem düzenli bakım gerektirir. Bunun içerisinde daha verimli çalışmasına ve işletme maliyetlerini düşürmesine yardımcı olmak için her yıl yapılan kapsamlı filtre temizliği yer alır. LG’nin üstün kompresör teknolojileri geniş bir sıcaklık aralığında sabit performansı destekler."
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( "button", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 bg-transparent border-0 v928ih", type: "button", onClick: this.Toggle },
                                        //Element
                                        React.createElement( "span", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0 c3x53z" },
                                            //Element
                                            "Single Split Sistemin iç ünitesini nereye kurmalıyım?",
                                            //Element
                                            React.createElement( Icon, {} )
                                        ),
                                        //Element
                                        React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 pt-2 m-0 d-none sf863s" },
                                            //Element
                                            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-1 text-start" },
                                                //Element
                                                "Her bir iç ünitesinin doğru yere kurulumu optimum performansa katkıda bulunur."
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-1 text-start" },
                                                //Element
                                                "Kaset Tipi : Tavana monteli kaset tipi üniteler; kafe, fırın ve giyim mağazası gibi ticari kullanım için mükemmel uygunluk sağlar. İdeal ortam konforu için ünitenin tavanda merkezi bir konuma kurulumu önerilir."
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-1 text-start" },
                                                //Element
                                                "Tavan Tipi Gizli Kanal : Tavan tipi gizli kanal, ofis veya otel gibi birden çok odalı mekanlarda aynı anda soğutma olanağı sağlar. Kanalın, havalandırmayı tıkayabilecek herhangi bir engel olmayacak şekilde duvara veya tavana kurulumu önerilir."
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-1 text-start" },
                                                //Element
                                                "Salon Tipi : Optimum hava akışı sağlamak için salon tipi ünite ideal olarak pencereyle aynı yöne bakacak şekilde kurulmalıdır ve havalandırmayı tıkayabilecek engeller bulunmamalıdır."
                                            ),
                                            //Element
                                            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-1 text-start" },
                                                //Element
                                                "Duvar Tipi : Duvara monteli ünitenin ideal kurulumu için, üniteyi odanın merkezinde tavana yakın ve havalandırmayı tıkayabilecek engeller olmayacak şekilde yerleştirin."
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( "button", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 bg-transparent border-0 v928ih", type: "button", onClick: this.Toggle },
                                        //Element
                                        React.createElement( "span", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0 c3x53z" },
                                            //Element
                                            "Single Split ve Multi Split arasındaki fark nedir?",
                                            //Element
                                            React.createElement( Icon, {} )
                                        ),
                                        //Element
                                        React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 pt-2 m-0 d-none sf863s" },
                                            //Element
                                            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-1 text-start" },
                                                //Element
                                                "Single Split tek bir iç ünitesinin tek bir dış ünitesiyle eşleştiği klima sistemini ifade eder. Multi Split Sistem ise birden çok iç ünitesinin tek bir iç ünitesiyle çalışmasını sağlar."
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Single;
//Install
import React, { Component } from "react";
//Install
import Group from "../../Shortcode/Cookie/Group/Default";
//Install
import Bound from "../../Shortcode/Cookie/Bound";
//Install
import Target from "../../Shortcode/Cookie/Target";
//Install
import Describing from "../../Shortcode/Cookie/Describing";
//Install
import Performance from "../../Shortcode/Cookie/Performance";

//Element
class Setting extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative d-none b095qv" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 c389ng" },
                //Element
                React.createElement( "h6", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 h345zp" },
                    //Element
                    "Gizlilik Tercihi Merkezi"
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 u30k1l" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-3 a246p3" },
                        //Element
                        "1. Hakkınızda Topladığımız Bilgiler"
                    ),
                    //Element
                    React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 q73uu8" },
                        //Element
                        "Bilgilerinizin sorumluluğu size aittir. Bizim ve iş ortaklarımızın topladığı verilerle ilgili ayarlarınızı burada yönetebilirsiniz. Daha fazla bilgi için Tanımlama Bilgisi Politikamız ile Gizlilik Politikamızı inceleyebilirsiniz."
                    ),
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 a246p3" },
                        //Element
                        "2. İş Ortaklarımızın Topladığı Bilgiler"
                    ),
                    //Element
                    React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 q73uu8" },
                        //Element
                        "Etkileşimlerinizde size mümkün olan en iyi deneyimi sunmak için iş ortaklarıyla çalışırız."
                    ),
                    //Element
                    React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 q73uu8" },
                        //Element
                        "Bu iş ortakları, reklam kanalları ve sosyal ağlar aracılığıyla ilgi alanlarınıza daha uygun şekilde reklamları özelleştirmek için tanımlama bilgilerini ve diğer mekanizmaları kullanır. Aşağıdaki reklam kutularının işaretini kaldırarak devre dışı bırakabilirsiniz."
                    ),
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 b54b9b" },
                        //Element
                        React.createElement( Bound, {} ),
                        //Element
                        React.createElement( Describing, {} ),
                        //Element
                        React.createElement( Performance, {} ),
                        //Element
                        React.createElement( Target, {} )
                    ),
                    //Element
                    React.createElement( Group, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Setting;
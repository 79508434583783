//Install
import React, { Component } from "react";

//Element
class Default extends Component {
    //Element
    YQH897 = (e) => {
        //Element
        e.preventDefault()
        //Element
        let W28H8M = document.getElementsByTagName( "body" )[0]
        //Element
        let S9Q216 = document.getElementsByClassName( "c6j6q7" )[0]
        //Element
        S9Q216.classList.toggle( "d-none" )
        //Element
        W28H8M.classList.toggle( "overflow-hidden" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 v24m05" },
            //Element
            React.createElement( "button", { id: "Web_SV5152", name: "Web_SV5152", className: "float-start w-100 p-0 m-0 mt-3 mb-3 h7b847", type: "button", onClick: this.YQH897 },
                //Element
                "Tümünü Kabul Edin"
            ),
            //Element
            React.createElement( "button", { id: "Web_F77X3Y", name: "Web_F77X3Y", className: "float-start w-100 p-0 m-0 ms-4 mt-3 mb-3 f09035", type: "button", onClick: this.YQH897 },
                //Element
                "Tümünü Reddet"
            ),
            //Element
            React.createElement( "button", { id: "Web_CU463S", name: "Web_CU463S", className: "float-start w-100 p-0 m-0 ms-4 mt-3 mb-3 f09035", type: "button", onClick: this.YQH897 },
                //Element
                "Tercihleri Bildir"
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;
//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Dialog extends Component {
    //Element
    N51616 = (e) => {
        //Element
        e.preventDefault()
        //Element
        let YI9454 = document.getElementsByClassName( "c6j6q7" )[0]
        //Element
        let N722KN = document.getElementsByTagName( "body" )[0]
        //Element
        YI9454.classList.toggle( "d-none" )
        //Element
        //N722KN.classList.toggle( "overflow-hidden" )
    }
    //Element
    UF88W2 = (e) => {
        //Element
        e.preventDefault()
        //Element
        let C1J96A = document.getElementsByClassName( "b1046d" )[0]
        //Element
        let YI9454 = document.getElementsByClassName( "b095qv" )[0]
        //Element
        let N722KN = document.getElementsByTagName( "body" )[0]
        //Element
        C1J96A.classList.toggle( "d-none" )
        //Element
        YI9454.classList.toggle( "d-none" )
        //Element
        //N722KN.classList.toggle( "overflow-hidden" )
        
    }
    //Element
    render() {
        //Element
        const { ID, Title } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative b1046d" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-absolute start-0 bottom-0 r26k5m" },
                //Element
                React.createElement( "h6", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 s8529h" },
                    //Element
                    Title
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 c61044" },
                    //Element
                    React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 fi6d43" },
                        //Element
                        "Sitemizde, içeriğin tarafınıza sağlanması, Site’nin performansının optimize edilmesi ve ziyaretçi profilinin anlaşılması için gerekli olan çerezler kullanılmaktadır."
                    ),
                    //Element
                    React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 fi6d43" },
                        //Element
                        "Site üzerinde kullanılan bu çerezler hakkında detaylı bilgi almak için Çerez Aydınlatma Metni’ni incelemenizi rica ederiz."
                    )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 mt-3 z1714g" },
                    //Element
                    React.createElement( "button", { id: "Web_F1531H", name: "Web_F1531H", className: "float-start w-100 p-0 m-0 mb-3 d203e2", type: "button", onClick: this.N51616 },
                        //Element
                        "Kabul Et"
                    ),
                    //Element
                    React.createElement( "button", { id: "Web_Z059J4", name: "Web_Z059J4", className: "float-start w-100 p-0 m-0 ms-2 mb-3 dz5v37", type: "button", onClick: this.N51616 },
                        //Element
                        "Reddet"
                    ),
                    //Element
                    React.createElement( "button", { id: "Web_U361UN", name: "Web_U361UN", className: "float-start w-100 p-0 m-0 ms-2 mb-3 r7yt40", type: "button", onClick: this.UF88W2 },
                        //Element
                        "Tercihler"
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Dialog.propTypes = {
    ID    : propTypes.string.isRequired,
    Title : propTypes.string.isRequired
}
//Element
export default Dialog;
//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Icon } from "../../../Media/Icon/011.svg";

//Element
class Share extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "i", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-relative d11g5z" },
            //Element
            React.createElement( Icon, {} ),
            //Element
            React.createElement( "label", { className: "float-start w-100 p-0 m-0 text-center position-absolute nme879" },
                //Element
                "Hemen Gönder"
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Share;
//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Menu from "./Footer/Menu";
//Install
import Share from "./Column/Group/Share";
//Install
import Basket from "./Column/Group/Added";
//Install
import Cookie from "./Column/Cookie/Default";
//Install
import Success from "./Column/Group/Success";
//Install
import Contact from "./Column/Contact/Default";
//Install
import Support from "./Column/Quick/Support";
//Install
import Favorite from "./Column/Group/Favorite";
//Install
import Copyright from "./Footer/Copyright";
//Install
import Information from "./Footer/Information";

//Element
class Footer extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "footer", { className: "float-start w-100 p-0 m-0 eg1579" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 vp581g" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:8 },
                            //Element
                            React.createElement( Menu, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:1 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-start p-0 m-0 mt-5 qi0g80" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 i0s907" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Information, { ID: "0", Mobile: "0532 120 60 83", Telephone: "0322 504 44 55" } )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Copyright, { ID: "0", Title: "Telif Hakkı © 2024 Erka Klima Sistemleri Limited Şirketi Tüm Hakları Saklıdır" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Support, {} ),
            //Element
            React.createElement( Favorite, {} ),
            //Element
            React.createElement( Basket, {} ),
            //Element
            React.createElement( Share, {} ),
            //Element
            React.createElement( Success, {} ),
            //Element
            React.createElement( Contact, {} ),
            //Element
            React.createElement( Cookie, {} )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Footer;